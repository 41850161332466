import { paths } from '~/lib/paths'
import locales from '~/i18n/locales'
import { ModelApiKey } from '~/types/models'
import { LocaleCode } from '~/types/locales'

export default function (model: ModelApiKey) {
  const route = useRoute()
  const config = useRuntimeConfig()
  const { defaultLocale } = useI18n()
  const { locale } = useI18n()

  const links: { id: string; rel: string; href: string; hreflang?: string }[] = []

  if (model === ModelApiKey.Index) {
    links.push({
      id: 'i18n-can',
      rel: 'canonical',
      href:
        // prettier-ignore
        defaultLocale === locale.value
          ? config.public.appUrl + route.fullPath
          : route.fullPath.endsWith('/')
            ? config.public.appUrl + route.fullPath.slice(0, -1)
            : config.public.appUrl + route.fullPath + '/'
    })

    links.push({
      id: 'i18n-xd',
      rel: 'alternate',
      href: config.public.appUrl,
      hreflang: 'x-default'
    })
  } else {
    links.push({
      id: 'i18n-can',
      rel: 'canonical',
      href: route.fullPath.endsWith('/')
        ? config.public.appUrl + route.fullPath.slice(0, -1)
        : config.public.appUrl + route.fullPath + '/'
    })
  }

  if (model === ModelApiKey.Index) {
    locales.forEach(loc => {
      if (loc.code === locale.value || !(route.meta.nuxtI18n as string[]).includes(loc.code)) {
        return
      }

      links.push({
        id: 'i18n-alt-' + loc.code,
        rel: 'alternate',
        href: [config.public.appUrl, loc.code !== defaultLocale ? loc.code : undefined].filter(Boolean).join('/'),
        hreflang: loc.code
      })
    })
  } else {
    locales.forEach(loc => {
      if (loc.code === locale.value || !(route.meta.nuxtI18n as Record<string, string>)[loc.code]) {
        return
      }

      links.push({
        id: 'i18n-alt-' + loc.code,
        rel: 'alternate',
        href: [
          config.public.appUrl,
          loc.code !== defaultLocale && loc.code,
          paths[loc.code][model].path,
          (route.meta.nuxtI18n as Record<string, string>)[loc.code]
        ]
          .filter(Boolean)
          .join('/'),
        hreflang: loc.code
      })
    })

    links.push({
      id: 'i18n-xd',
      rel: 'alternate',
      href: [
        config.public.appUrl,
        paths[defaultLocale as LocaleCode][model].path,
        (route.meta.nuxtI18n as Record<string, string>)[defaultLocale]
      ]
        .filter(Boolean)
        .join('/'),
      hreflang: 'x-default'
    })
  }

  return links
}
